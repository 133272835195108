@import url('https://fonts.googleapis.com/css2?family=Lato&family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@600&display=swap');

body {
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;
  margin: 0;
  width: 100%;
}

.content, .section {
  background-color: white;
}

/* app css */

.App {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  background-color: white;
  font-family: 'Lato', sans-serif;
  align-content: center;
}

.highlight {
  font-weight: 800;
  text-shadow: 5px 5px 0px #fde6dd;
}

.fade-in {
  opacity: 0;
  transform: translateY(15vh);
  visibility: hidden;
  transition: opacity 1s ease-out, transform 1s ease-out, delay 2s;
  will-change: opacity, visibility;
}

.fade-in.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}

/* nav css */

.name-logo {
  display: flex;
  font-family: 'Playfair Display', serif;
  font-weight:700;
  margin-left: 100px;
  align-items: center;
  cursor:default;
  font-size: 45px;
  background-color: white;
}

.nav {
  display: flex;
  color: black;
  background-color: white;
  justify-content: space-between;
  height: 150px;
  width: 100%;
  position:fixed;
  top: 0;
  right: 0;
  left: 0;
  cursor:pointer;
  z-index: 10;
}

.nav-elements {
  padding: 25px 50px;
  margin: 0;
  justify-content: flex-end;
  gap: 35px;
  display: flex;
  background-color: var(--off-white);
}

.nav-elements ul {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
}

.nav-elements ul li:not(:last-child) {
  margin-right: 60px;
}

.nav-elements ul a {
  font-size: 25px;
  font-weight: 400;
  text-decoration: none;
}

.nav-elements ul a.active {
  font-weight: 500;
  position: relative;
}

.nav-elements ul a.active::after {
  content: '';
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 100%;
  height: 4px;
  background-color:#c5d7f9;
}

/* section css */


/* intro css */

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes slideIn {
  from {
    margin-bottom: 100%;
    height: 70%;
  }
}


.intro {
  background-image: url('./images/clouds-back.jpeg');
  z-index: 0;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  vertical-align: middle;
}

.intro-title h1 {
  font-family: 'Playfair Display', serif;
  font-weight: 600;
  text-shadow: 5px 3px 3px #ffffff;
  transform:;
  color: #000000;
  font-size: 100px;
  margin: 0;
  animation-timing-function: ease-in;
  animation: slideIn 2s, fadeIn 4s;
}

.intro-text h2 {
  text-shadow: 5px 3px 3px #ffffff;
  color: #000000;
  font-size: 40px;
  margin: 0;
}

.intro-text {
  animation: fadeIn 5s;
}

.contact {
  padding-top: 15px;
  padding-bottom: 15px;
  z-index: 5;
  animation: fadeIn 5s;
}

.content {
  background-color: white;
}

/* about section css */

.section-content#aboutme {
  margin: 0px;
  padding-bottom: 200px;
  color: black;
  background-color: white;
}

.about-section h1 {
  font-family: 'Playfair Display', serif;
  font-weight: 900;
  font-size: 60px;
  z-index: 10;
  text-decoration: underline;
  text-decoration-color: #c2d5fa;
  padding-top: 100px;
  padding-bottom: 50px;
  text-align: center;
  background-color: white;
}

.about-section .front-clouds {
  background: url("./images/clouds-front.png") bottom;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100vh;
  width: 100%;
  bottom: 0;
  left: 0;
  position: absolute;
}

.about-container {
  max-width: 1250px;
  margin: 0 auto;
  display: grid;
  padding: 1.5em;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  background-color: white;
}

.about-pic {
  background: url("./images/avatar-real.jpg") no-repeat center;
  background-size: cover;
  min-height: 600px;
}

.about-text {
  font-size: 20px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
}

#about2 {
  display: none;
}

#about2 a {
  text-decoration: none;
  color: #729df3;
}

#about2 a:visited {
  color: #729df3;
} 

#about-button {
  display: flex;
  color: #000000;
  background-color: #c5d7f9;
  padding: 10px 16px;
  border-radius: 20px;
  border: none;
  transition: all .3s ease;
  cursor: pointer;
  align-items: center;
  font-size: 20px;
  width: fit-content;
  margin-top: 10px;
  align-self: center;
}

#about-button:hover {
  background-color: #d9e3f5;
}

@media only screen and (max-width: 650px) {
  .about-pic {
    height: 400px;
  }
  }

/* skills section css */

.skills-section {
  width: 100%;
}

#skills h1 {
  text-align: center;
  font-family: 'Playfair Display', serif;
  font-weight: 900;
  font-size: 60px;
  text-decoration: underline;
  text-decoration-color: #c2d5fa
}

#skills {
  color: rgb(0, 0, 0);
  display: flex;
  margin: auto;
}

.skills-container {
  display: grid;
  max-width: 850px;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  margin: 50px auto;
}

.skills {
  padding: 2em;
  text-align: center;
}

.text {
  display: block;
  font-size: 20px;
  font-weight: 600;
}


/* project section css */

.projects {
  width: 100%;
  padding-bottom: 200px;
}

.project-text h1 {
  color: rgb(0, 0, 0);
  font-family: 'Playfair Display', serif;
  font-weight: 900;
  font-size: 60px;
  text-decoration: underline;
  text-decoration-color: #c5d7f9;
  padding: 50px 0;
  text-align: center;
}

.cards-container {
  max-width: 1200px;
  margin: auto;
}

.card {
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  margin: 0 auto;
  display: grid;
  gap: 3rem;
  padding: 1rem;
  align-items: center;
}

.card:nth-child(odd) .card-image{
  order: 2;
}

.card:nth-child(odd) .card-text{
  text-align: right;
}

.card-image {
  min-height: 400px;
}


#badbunch-card {
  background: url('./images/BadBunch.png') no-repeat top ;
  background-size: cover;
}

#calculator-card {
  background: url('./images/Calculator.png') no-repeat top;
  background-size: cover;
}

#tictactoe-card {
  background: url('./images/TicTacToe.png') no-repeat top;
  background-size: cover;
}

#todo-card {
  background: url('./images/Todo List.png') no-repeat top;
  background-size: cover;
}


/* contact section css */


input[type=text], input[type=email], select, textarea {
  width: 100%; /* Full width */
  padding: 15px; /* Some padding */ 
  border: 1px solid #ccc; /* Gray border */
  border-radius: 4px; /* Rounded borders */
  box-sizing: border-box; /* Make sure that padding and width stays in place */
  margin-top: 6px; /* Add a top margin */
  margin-bottom: 16px; /* Bottom margin */
  resize: vertical; /* Allow the user to vertically resize the textarea (not horizontally) */
  font-family:'Lato', sans-serif;
  font-size: large;
}

textarea {
  height: 200px;
}

button[type=submit] {
  background-color: white;
  color: black;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-family:'Lato', sans-serif;
  font-size: large;
}

button[type=submit]:hover {
  background-color: #ececec;
}

.contact-section {
  padding: 100px 0;
  background-color: #c2d5fa;
}



.contact-container {
  max-width: 1500px;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  margin: 0 auto;
  gap: 3rem;
  padding: 3rem;
  display: grid;
  border-radius: 5px;
  background-color: #c2d5fa;
  padding-bottom: 200px;
  padding-top: 150px;
}

.contact-media {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contact-title {
  font-size: 30px;
  text-align: center;
}

.contact-title span {
  font-family: 'Playfair Display', serif;
  font-size: 60px;
  font-weight: bold;
  text-shadow: 5px 5px 0px #fde6dd;
}

.contact-title span.arrow {
  font-family: 'Playfair Display', serif;
  font-size: 55px;
  font-weight: bold;
  padding-left: 5px;
}

.contact-pic {
  background: url("./images/white-cloud3.png") center no-repeat;
  background-size: contain;
  height: 450px;
  width: 450px;
  filter: drop-shadow(20px 10px 10px rgb(156, 156, 156));
  animation: move 5s ease infinite;
}

@keyframes move {
  50% {transform: translateY(-15px);}
}

/* footer css */

.footer {
  display: flex;
  flex-direction: column;
  font-size: 30px;
  text-align: center;
  color: black;
  width: 100%;
  justify-content: center;
  background-color: rgb(255, 255, 255);
  border-radius: 100% 100% 0 0;
  border: none;
  height: 200px;
  margin-top: -150px;
  z-index: 10;
}

.footer-logo {
  font-family: 'Playfair Display', serif;
  font-size: 30px;
  font-weight: bold;
  padding: 10px;
}

.footer-text {
  padding: 10px 0px;
  font-size: 20px;
}

/* hamburger menu hidden css */

.menu-icon {
  display: none;
}

@media (max-width: 375px) {
  .card {
    display: block;
  }

  .contact-container {
    padding: 150px 0;
    width: 100%;
    display: block;
  }

  .contact-title {
    font-size: 20px;
  }

  .contact-title span {
    font-size: 50px;
  }

  .contact-form {
    padding: 10px;
  }
}

@media (max-width: 768px) {
  .nav-elements ul li:not(:last-child) {
    margin-right: 30px;
  }

  .skills-container {
    grid-template-columns: repeat(2, minmax(150px, 1fr));
  }
}

@media screen and (max-width: 900px) {

  .App {
    margin: 0;
    border-radius: 0;
    overflow: hidden;
  }

  .name-logo {
    font-size: 25px;
    margin-left: 15px;
  }

  .intro-title h1 {
    font-size: 40px;
    padding: 10px;
  }

  .intro-text{
    padding: 0;
    margin: 0;
  }

  .intro-text h2 {
    font-size: 15px;
  }

  .section-content h1 {
    font-size: 40px;
  }

  #skills h1 {
    font-size: 40px;
  }

  .cards-container {
    text-align: center;
  }

  .card:nth-child(odd) .card-image{
    order: unset;
  }

  .project-text h1 {
    margin-top: 155px;
  }

  h2 {
    font-size: 25px;
  }

  .nav {
    height: fit-content;
  }

  .menu-icon {
    display:flex;
    justify-content: flex-end;
    cursor: pointer;
    z-index: 2;
    padding: 10px;
  }

  .nav-elements {
    position: absolute;
    right: 0;
    top: 0px;
    background-color:var(--off-white);
    width: 0;
    transition: all 0.3s ease-in;
    overflow: hidden;
    padding: 10px;
  }

  .nav-elements.active {
    width: 180px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
    z-index: 1;
  }

  .nav-elements ul {
    display: flex;
    flex-direction: column;
  }

  .nav-elements ul li {
    margin-right: unset;
    margin-top: 22px;
  }

  .card:nth-child(odd) .card-text {
    text-align: center;
  }

  .contact-pic {
    width: 300px;
    height: 300px;
  }

  .footer-text {
    padding: 5px;
  }

  .card {
    display: block;
  }

  h1 {
    margin: 0;
  }

  .about-section h1 {
    background-color: white;
  }

  .about-section .front-clouds {
    background: url("./images/clouds-front.png") bottom no-repeat;
    background-size: cover;
    height: 40%;
    bottom: -125px;
  }

  .intro {
    background-attachment: initial;
  }

}
